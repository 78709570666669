import React, { useState, useEffect, useContext } from 'react'
import DashboardWrapper from '../../../components/BestSchoolsAwards/DashboardWrapper/DashboardWrapperTide'
import * as DashboardStyles from './Dashboard.module.scss'
import { Helmet } from 'react-helmet'
import { FormFields } from '../../../components/BestSchoolsAwards/Form/TideForm'
import getEntries from '../../../api/women-in-business/get-entries'
import { LoginContext } from '../../../lib/utils'

const BSADashboard = () => {
  const [entries, setEntries] = useState<FormFields[]>()
  const [error, setError] = useState<string>()
  const { usr } = useContext(LoginContext)

  const existingEntries = async () => {
    if (usr?.viewer?.email) {
      try {
        await getEntries(usr?.viewer?.email)
          .then(res => {
            return res.data
          })
          .then(data => {
            if (typeof data === 'string') {
              throw new Error(data)
            } else {
              setEntries([data])
            }
          })
      } catch (error: any) {
        setError(
          "It looks like you haven't entered. The competition is now closed for new entries."
        )
      }
    }
  }

  useEffect(() => {
    if (!usr?.viewer?.email) {
      setError('You need to be logged in.')
    }

    if (usr?.viewer?.email && !entries) {
      existingEntries()
    }
  }, [usr])

  return (
    <>
      <Helmet>
        <title>She Means Business | Muddy Stilettos</title>
      </Helmet>
      <DashboardWrapper>
        <div className={DashboardStyles.Intro}>
          <h1>5 STEPS TO COMPLETING YOUR ENTRY</h1>
          <p>
            <ol>
              <li>
                Create a Muddy Stilettos account on the dedicated Muddy
                Stilettos SHE MEANS BUSINESS Award site and select if you are an
                existing or new business.
              </li>
              <li>Download and complete the simple entry form. </li>
              <li>Submit your entry form.</li>
              <li>
                Open your free Tide Business Account – it takes under 10 mins to
                get set up entirely online through their website or mobile app.
              </li>
              <li>
                Confirm entry via the Tide x Muddy Stilettos welcome email
                (please check your junk and allow up to 12hrs for it to arrive
                in your inbox). Do <u>not</u> miss this step or your entry
                isn&apos;t valid!
              </li>
            </ol>
          </p>
          <p>
            <strong>
              <em>The competition is now closed to new entries.</em>
            </strong>
          </p>
          <p>You can see the status of any existing entries below.</p>
        </div>
        <div className={DashboardStyles.Intro}>
          <h1>Your Entry Details</h1>
          <div className={DashboardStyles.Entries}>
            {entries ? (
              entries.length > 0 &&
              entries?.map((entry, index) => {
                let CTA = <></>

                if (entry.have_confirmed_tide_account) {
                  CTA = (
                    <p>
                      You&apos;re all set! Sit tight and wait for the finalists
                      to be announced! Good luck!
                    </p>
                  )
                } else if (
                  entry.have_uploaded_pdf &&
                  !entry.have_confirmed_tide_account
                ) {
                  CTA = (
                    <>
                      <p>
                        You need to create your account on Tide and then click
                        the link which is received in your email to link your
                        account to the competition.
                      </p>
                      {/* <a
                        className={DashboardStyles.Button}
                        href={`https://www.tide.co/`}
                      >
                        Create Tide Account
                      </a> */}
                      <a
                        className={`${DashboardStyles.Button}`}
                        href={`/women-in-business/dashboard/enter/`}
                      >
                        Edit Entry
                      </a>
                    </>
                  )
                } else if (!entry.have_uploaded_pdf) {
                  CTA = (
                    <>
                      <p>
                        There are still some parts of your entry that need to be
                        completed
                      </p>
                      <a
                        className={DashboardStyles.Button}
                        href={`/women-in-business/dashboard/enter/`}
                      >
                        Finish Entry
                      </a>
                    </>
                  )
                }
                return (
                  <div key={index} className={DashboardStyles.Entry}>
                    {/* <div className={DashboardStyles.EntryRow1}>
                      <p
                        className={DashboardStyles.BusinessName}
                        dangerouslySetInnerHTML={{
                          __html: entry.business_name as string
                        }}
                      />
                    </div> */}
                    <div className={DashboardStyles.EntryRow2}>
                      <p>
                        <strong>Business Name</strong>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: entry.business_name as string
                          }}
                        />
                      </p>
                      <p>
                        <strong>Name</strong>
                        <span>{entry.name}</span>
                      </p>
                      <p className={DashboardStyles.Capitalize}>
                        <strong>Business Type </strong>
                        <span>
                          {entry.businessType
                            ? (entry.businessType as string)?.replace('-', ' ')
                            : '❌'}
                        </span>
                      </p>
                      <p>
                        <strong>Business Address </strong>
                        <span>{entry.business_address}</span>
                      </p>
                      <p>
                        <strong>Contact Number </strong>
                        <span>{entry.contact_number}</span>
                      </p>
                      <p>
                        <strong>Email </strong>
                        <span>{entry.email}</span>
                      </p>
                      <p>
                        <strong>Website URL</strong>
                        <span>{entry.website_url ?? 'n/a'}</span>
                      </p>
                      <p>
                        <strong>PDF Submitted</strong>
                        <span>{entry.have_uploaded_pdf ? '✅' : '❌'} </span>
                      </p>
                      <p>
                        <strong>Tide Account Created & Connected</strong>
                        <span>{entry.have_uploaded_pdf ? '✅' : '❌'} </span>
                      </p>
                    </div>
                    <p>
                      <strong>Next Steps:</strong>
                    </p>
                    <div className={DashboardStyles.NextSteps}>{CTA}</div>
                  </div>
                )
              })
            ) : (
              <>
                <p>{error}</p>
                {/* <a
                  href={`/women-in-business/dashboard/enter/`}
                  className={DashboardStyles.Button}
                >
                  Get Started
                </a> */}
              </>
            )}
          </div>
        </div>
      </DashboardWrapper>
    </>
  )
}

export default BSADashboard
